<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>اضافة صلاحية جديدة </CCardHeader>
        <CCardBody>
          <CForm>
            <CRow class="mt-3">
              <CCol sm="12" md="6">
                <CInput invalid description="" label="الاسم" horizontal autocomplete="name" v-model.trim="form.name"
                  invalid-feedback="يوجد خطأ في هذا الحقل" :is-valid="!$v.form.name.$anyError" />
              </CCol>
            </CRow>


            <CRow class="mt-5">
              <CCol sm="12" md="6">
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3">الصلاحيات</label>
                  <CCol sm="9">
                    <v-select :multiple="true" :options="permissions" v-model="form.permissions"
                      :reduce="(permission) => permission.id" dir="rtl" label="name" placeholder="اختار الصلاحيات">
                      <template> عذراً هذه الصلاحيات غير موجود </template>
                      <template v-if="$v.form.permissions.$anyError" #footer>
                        <div style="opacity: 0.8" class="text-danger mt-2">
                          يجب عليك اختيار الصلاحيات
                        </div>
                      </template>
                    </v-select>
                  </CCol>
                </div>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
        <CCardFooter>
          <CButton :disabled="loading" @click="submit" type="button" color="success">
            <CSpinner v-if="loading" color="white" size="sm" />
            <span v-else>
              <CIcon name="cil-save" /> حفظ
            </span>
          </CButton>
        </CCardFooter>
        <CCardFooter v-if="formErrors">
          <CAlert color="danger">
            <CListGroup flush>
              <CListGroupItem v-for="(err, index) in formErrors" :key="index">
                {{ err[0] }}
              </CListGroupItem>
            </CListGroup>
          </CAlert>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "Create",
  data() {
    return {
      loading: false,
      permissions: [],
      form: {
        name: "",
        permissions: []
      },
      formErrors: null,
    };
  },
  validations: {
    form: {
      name: { required, min: minLength(2) },
      permissions: { required }
    },
  },
  created() {
    this.fetchPermissions();
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return true;
      }
      this.loading = true;
      this.$http
        .post(`/roles`, this.form)
        .then((res) => {
          // handle success
          if (res.data.status == 200) {
            this.$router.push({ name: "Roles" });
          }
          this.loading = false;
        })
        .catch((error) => {
          // handle error
          if (error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
          this.loading = false;
        });
    },
    fetchPermissions() {
      this.$http.get(`/general/permissions`).then((res) => {
        this.permissions = res.data.data;
      });
    },
  },
};
</script>
